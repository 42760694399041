<template>
  <PasswordReset />
</template>

<script>
import PasswordReset from "@/components/auth/PasswordReset";
export default {
  name: "PasswordResetView",
  components: { PasswordReset }
};
</script>
